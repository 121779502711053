import React, {Component} from "react";

import logo from '../assets/images/logo.png';
import fb1 from '../assets/images/logo/abcPrime-facebook-1.png';
import fb2 from '../assets/images/logo/abcPrime-facebook-2.png';
import ig1 from '../assets/images/logo/abcPrime-ig-1.png';
import ig2 from '../assets/images/logo/abcPrime-ig-2.png';
import tiktok1 from '../assets/images/logo/abcPrime-tiktok-1.png';
import tiktok2 from '../assets/images/logo/abcPrime-tiktok-2.png';
import yt1 from '../assets/images/logo/abcPrime-youtube-1.png';
import yt2 from '../assets/images/logo/abcPrime-youtube-2.png';

class Footer extends Component {
    state = {
        fb: fb1,
        ig: ig1,
        tiktok: tiktok1,
        yt: yt1
    }


    render() {
        return (
            <footer className="footer" style={{color: '#ddd'}}>
                <img className="logo" src={logo} alt="ABC Logo"/>

                <div className="showroom">
                    <div className="desc">
                        <h3>Site and showroom</h3>
                        <p>N.Bacalso Ave., Corner V. Rama St., Cebu City <br/>
                            {/*Contact us at: 956-243-6291<br/>*/}
                            {/*Tel, no. 032-2381306</p>*/}
                            Phone Number: 032-2381306 <br/>
                            Mobile Number: +63 956 243 6291
                        </p>
                    </div>
                </div>

                {/*<div className="office">*/}
                {/*    <h3>Office</h3>*/}
                {/*    <p>1104 Insular Life Business Center<br/>*/}
                {/*        Mindanao Ave., cor. Biliran Road<br/>*/}
                {/*        Cebu Business Park, Cebu City 6000<br/>*/}
                {/*        Tel. No. 032-2381306</p>*/}
                {/*</div>*/}

                <div className="media">
                    <h3 className="icon-container">
                        <img
                            src={this.state.fb}
                            alt="Facebook"
                            onClick={() => window.open("https://www.facebook.com/arctowersofficial")}
                            onMouseEnter={() => this.setState({...this.state, fb: fb2})}
                            onMouseLeave={() => this.setState({...this.state, fb: fb1})}
                            style={{width: 40, marginTop: -20, cursor: 'pointer'}}
                        />
                        <img
                            src={this.state.ig}
                            alt="Instagram"
                            onClick={() => window.open("https://www.instagram.com/arctowersofficial")}
                            onMouseEnter={() => this.setState({...this.state, ig: ig2})}
                            onMouseLeave={() => this.setState({...this.state, ig: ig1})}
                            style={{width: 40, marginTop: -20, cursor: 'pointer', marginLeft: 20}}
                        />
                        <img
                            src={this.state.tiktok}
                            alt="Tiktok"
                            onClick={() => window.open("https://www.tiktok.com/@arctowers_official")}
                            onMouseEnter={() => this.setState({...this.state, tiktok: tiktok2})}
                            onMouseLeave={() => this.setState({...this.state, tiktok: tiktok1})}
                            style={{width: 40, marginTop: -20, cursor: 'pointer', marginLeft: 20}}
                        />
                        <img
                            src={this.state.yt}
                            alt="Youtube"
                            onClick={() => window.open("https://www.youtube.com/@arctowers-bd6dh")}
                            onMouseEnter={() => this.setState({...this.state, yt: yt2})}
                            onMouseLeave={() => this.setState({...this.state, yt: yt1})}
                            style={{width: 40, marginTop: -20, cursor: 'pointer', marginLeft: 20}}
                        />
                        {/*<a href="https://www.facebook.com/arctowersofficial" target="_blank"><i*/}
                        {/*    className="fab fa-facebook-f"></i></a>*/}
                        {/*<a href="https://www.instagram.com/arctowersofficial" target="_blank"><i*/}
                        {/*    className="fab fa-instagram"></i></a>*/}
                        {/*<a href="https://www.youtube.com/channel/UCFJXE6O4F3ex23k3aq0sB8Q" target="_blank"><i*/}
                        {/*    className="fab fa-youtube"></i></a>*/}
                    </h3>
                    <p>For questions and inquiries, message us through<br/>
                        <a href="mailto:inquiry@abcprime.com.ph">inquiry@abcprime.com.ph</a>
                    </p>
                </div>

                <div className="copyright">
                    © ABC PRIME 2024
                </div>
            </footer>
    );
    }
    }

    export default Footer;
